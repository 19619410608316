import { TipoSimulacaoEnum, BOT_NAME } from "../utils/constants";
import collabs from "./collabs";

const offers = {
  HOME: {
    botType: BOT_NAME.HOME,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 161,
    empresaId: 90,
    desconto: false,
    navBar: true,
    tipoTabela: "completa",
    brand: "mycon",
    gtmId: "GTM-PVQ72FR",
    siteDomain: "https://simule.mycon.com.br",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/home-promo-04-2025.png",
    linkWhatsApp: "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  APP: {
    botType: BOT_NAME.APP,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 161,
    empresaId: 90,
    desconto: false,
    navBar: false,
    tipoTabela: "completa",
    brand: "mycon",
    gtmId: "GTM-PVQ72FR",
    siteDomain: "https://simule-app.mycon.com.br",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/home-promo-04-2025.png",
    linkWhatsApp: "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  PERFORMANCE: {
    botType: BOT_NAME.PERFORMANCE,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 759,
    compraId: 176,
    empresaId: 102,
    desconto: true,
    navBar: true,
    tipoTabela: "completa",
    gtmId: "GTM-PVQ72FR",
    siteDomain: "https://simule.mycon.com.br/performance",
    brand: "mycon",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/banner_consumidor-b.png",
    linkWhatsApp: "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  OFERTA: {
    botType: BOT_NAME.OFERTA,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 164,
    empresaId: 93,
    desconto: true,
    navBar: true,
    tipoTabela: "completa",
    brand: "mycon",
    gtmId: "GTM-PVQ72FR",
    siteDomain: "https://simule.mycon.com.br/oferta",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/home-promo-04-2025.png",
    linkWhatsApp: "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  ABANDONO_IMOVEL: {
    botType: BOT_NAME.ABANDONO_IMOVEL,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 161,
    empresaId: 90,
    desconto: false,
    tipoTabela: "completa",
    brand: "mycon",
    gtmId: "GTM-PVQ72FR",
    siteDomain: "https://simule.mycon.com.br/abandono-imovel",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/home-promo-04-2025.png",
    linkWhatsApp: "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },

  ...collabs,
};

export default offers;
