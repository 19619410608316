const tipoPlanoNome = {
  CREDITO_PAGAMETADE: "Paga metade",
  CREDITO_INTEGRAL: "Integral",
};

const TipoSimulacaoEnum = {
  CREDITO_INTEGRAL: "CREDITO_INTEGRAL",
  CREDITO_PAGAMETADE: "CREDITO_PAGAMETADE",
  PARCELA_REDUZIDA: "PARCELA_REDUZIDA",
};

const BOT_NAME = {
  HOME: "HOME",
  APP: "APP",
  PERFORMANCE: "PERFORMANCE",
  OFERTA: "OFERTA",
  ABANDONO_IMOVEL: "ABANDONO_IMOVEL",
  MYCOINS: "MYCOINS",
  LIVE: "LIVE",
  ANIVERSARIO: "ANIVERSARIO",
  ANIVERSARIO_FINAL_DE_SEMANA: "ANIVERSARIO_FINAL_DE_SEMANA",
  FINDE_TURBO: "FINDE_TURBO",
  FINDE: "FINDE",
  CONECTA: "CONECTA",
  CRM: "CRM",
  COLLAB_99: "COLLAB_99",
  COLLAB_99_ROTA99: "COLLAB_99_ROTA99",
  COLLAB_99_PAY: "COLLAB_99_PAY",
  COLLAB_PREMMIA: "COLLAB_PREMMIA",
  COLLAB_GAZETA: "COLLAB_GAZETA",
  COLLAB_SALARY_FITS: "COLLAB_SALARY_FITS",
  COLLAB_KMV: "COLLAB_KMV",
  COLLAB_LIVELO: "COLLAB_LIVELO",
  COLLAB_SEM_PARAR: "COLLAB_SEM_PARAR",
  COLLAB_FINANZERO: "COLLAB_FINANZERO",
  COLLAB_CLUBE_CERTO: "COLLAB_CLUBE_CERTO",
  COLLAB_CLUBE_GIRO: "COLLAB_CLUBE_GIRO",
  COLLAB_EASY_CREDITO: "COLLAB_EASY_CREDITO",
  COLLAB_AUTO_MAIS: "COLLAB_AUTO_MAIS",
  COLLAB_AUTO_MAIS_APP: "COLLAB_AUTO_MAIS_APP",
  COLLAB_TRUCADAO: "COLLAB_TRUCADAO",
  COLLAB_STOPCLUB: "COLLAB_STOPCLUB",
};

const TipoBotEnum = {
  [BOT_NAME.HOME]: {
    textChat1:
      "Olá, eu sou o Mycon! E você está prestes a conquistar de uma forma econômica, prática e segura.",
    textChat2:
      "<b>É sem juros, sem entrada</b> e você tem o melhor plano em mãos em poucos cliques.",
  },
  [BOT_NAME.APP]: {
    textChat1:
      "Olá, eu sou o Mycon! E você está prestes a conquistar de uma forma econômica, prática e segura.",
    textChat2:
      "<b>É sem juros, sem entrada</b> e você tem o melhor plano em mãos em poucos cliques.",
  },
  [BOT_NAME.PERFORMANCE]: {
    textChat1:
      "Oi, eu sou o Mycon! Aqui você garante o seu sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!</b>",
    textChat2:
      "Quer ficar mais perto da conquista? Aproveite a PROMO do Mês do Consumidor Mycon:",
  },
  [BOT_NAME.ABANDONO_IMOVEL]: {
    textChat1:
      "Olá, eu sou o Mycon! E você está prestes a conquistar de uma forma econômica, prática e segura.",
    textChat2:
      "<b>É sem juros, sem entrada</b> e você tem o melhor plano em mãos em poucos cliques.",
  },
  [BOT_NAME.OFERTA]: {
    textChat1:
      "Olá, eu sou o Mycon! E você está prestes a conquistar de uma forma econômica, prática e segura.",
    textChat2:
      "<b>É sem juros, sem entrada</b> e você tem o melhor plano em mãos em poucos cliques.",
  },
  [BOT_NAME.MYCOINS]: {
    textChat1:
      "Oi, eu sou o Mycon! Conte comigo para conquistar com o <b>MELHOR PREÇO do mercado. E com taxas a partir de 9,99% por todo o período.</b>",
    textChat2:
      "<b>Ahh</b>, e por estar sendo indicado para o Mycon, você ainda vai ganhar 50% de desconto na sua primeira parcela!",
  },
  [BOT_NAME.COLLAB_99]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Eu e a <b>99</b> oferecemos o jeito mais barato para você, <b>motorista parceiro</b>, comprar seu <b>carro ou moto</b> de forma mais barata.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros</b>, sem entrada e com a <b>melhor taxa do Brasil</b>. Simule!",
  },
  [BOT_NAME.COLLAB_99_ROTA99]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Eu e a <b>Rota 99</b> oferecemos o jeito mais barato para você, <b>motorista parceiro</b>, comprar seu <b>carro ou moto</b> de forma mais barata.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_99_PAY]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>99Pay</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_PREMMIA]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>Premmia</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_GAZETA]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com <b>A Gazeta</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_SALARY_FITS]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>SalaryFits</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_KMV]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>KM de Vantagens</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_LIVELO]: {
    textChat1:
      "Olá, eu sou o <b>Mycon!</b> Vou te mostrar o <b>jeito mais barato</b> para você comprar tudo o que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_SEM_PARAR]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>Sem Parar</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_FINANZERO]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>Finanzero</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_CLUBE_CERTO]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>Clube Certo</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_CLUBE_GIRO]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>Clube Giro</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_EASY_CREDITO]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>Easy Crédito</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_AUTO_MAIS]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com a <b>Auto Mais</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_AUTO_MAIS_APP]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Comprando hoje, você paga apenas a <b>METADE</b> das parcelas até ser contemplado e ainda <b>ganha 50% de desconto</b> na primeira parcela!",
    textChat2:
      "Essa é uma condição <b>EXCLUSIVA</b> que eu e o <b>Auto+</b> preparamos para você, usuário do app, comprar aquilo que deseja de forma mais barata. <b>Corre pra aproveitar!</b>",
  },
  [BOT_NAME.COLLAB_TRUCADAO]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com o <b>Trucadão</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
  [BOT_NAME.COLLAB_STOPCLUB]: {
    textChat1:
      "Olá, eu sou o Mycon!</b> Junto com o <b>StopClub</b>, estamos oferecendo o <b>jeito mais barato</b> para você <b>comprar</b> tudo aquilo que deseja.",
    textChat2:
      "Faça agora seu consórcio <b>sem juros, sem entrada</b> e com a <b>melhor taxa do Brasil.</b> Simule!",
  },
};

const ProdutosEnum = {
  IMOVEL: "IMOVEL",
  CARRO: "CARRO",
  MOTO: "MOTO",
  SERVICO: "SERVICO",
  CAMINHAO: "CAMINHAO",
  MAQUINAS: "MAQUINAS",
};

const ProdutosNomes = {
  IMOVEL: "IMÓVEL",
  CARRO: "CARRO",
  MOTO: "MOTO",
  SERVICO: "SERVIÇO",
  CAMINHAO: "CAMINHÃO",
  MAQUINAS: "MÁQUINAS",
};

const valoresSimulacao = {
  [TipoSimulacaoEnum.CREDITO_INTEGRAL]: {
    [ProdutosEnum.IMOVEL]: { min: 100000, max: 700000 },
    [ProdutosEnum.CARRO]: { min: 30000, max: 400000 },
    [ProdutosEnum.MOTO]: { min: 10000, max: 120000 },
    [ProdutosEnum.SERVICO]: { min: 12000, max: 20000 },
    [ProdutosEnum.CAMINHAO]: { min: 100000, max: 400000 },
    [ProdutosEnum.MAQUINAS]: { min: 100000, max: 400000 },
  },
  [TipoSimulacaoEnum.CREDITO_PAGAMETADE]: {
    [ProdutosEnum.IMOVEL]: { min: 100000, max: 700000 },
    [ProdutosEnum.CARRO]: { min: 30000, max: 400000 },
    [ProdutosEnum.MOTO]: { min: 10000, max: 120000 },
    [ProdutosEnum.SERVICO]: { min: 0, max: 0 },
    [ProdutosEnum.CAMINHAO]: { min: 100000, max: 400000 },
    [ProdutosEnum.MAQUINAS]: { min: 100000, max: 400000 },
  },
};

const CategoriasEnum = {
  1: {
    title: "Imóveis",
    description: "Novos ou usados",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
    produtoCategoria: "imoveis",
  },
  2: {
    title: "Automóveis",
    description: "Novos ou seminovos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
    produtoCategoria: "automoveis",
  },
  3: {
    title: "Motocicletas",
    description: "Nacionais ou importadas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
    produtoCategoria: "motos",
  },
  4: {
    title: "Serviços",
    description: "Festas, viagens e mais",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
    produtoCategoria: "servicos",
  },
  6: {
    title: "Pesados",
    description: "Caminhões e Equipamentos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
    produtoCategoria: "caminhoes_equipamentos",
  },
  20: {
    title: "Imóveis",
    description: "Novos ou usados",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
    produtoCategoria: "imoveis",
  },
  21: {
    title: "Automóveis",
    description: "Novos ou seminovos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
    produtoCategoria: "automoveis",
  },
  22: {
    title: "Motocicletas",
    description: "Nacionais ou importadas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
    produtoCategoria: "motos",
  },
  26: {
    title: "Pesados",
    description: "Caminhões e Equipamentos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
    produtoCategoria: "caminhoes_equipamentos",
  },
};

const CategoriaPagamento = {
  CREDITO_INTEGRAL: {
    IMOVEL: 1,
    CARRO: 2,
    MOTO: 3,
    SERVICO: 4,
    CAMINHAO: 6,
    MAQUINAS: 6,
  },
  CREDITO_PAGAMETADE: {
    IMOVEL: 20,
    CARRO: 21,
    MOTO: 22,
    SERVICO: 0, // verificar qual é o id
    CAMINHAO: 26,
    MAQUINAS: 26,
  },
  PARCELA_REDUZIDA: {
    IMOVEL: 29,
    CARRO: 30,
    MOTO: 31,
    SERVICO: 0, // verificar qual é o id
    CAMINHAO: 32,
    MAQUINAS: 32,
  },
};

const categoryNames = {
  1: "IMOVEL",
  2: "CARRO",
  3: "MOTO",
  4: "SERVICO",
  6: "CAMINHAO",
  20: "IMOVEL",
  21: "CARRO",
  22: "MOTO",
  26: "CAMINHAO",
  29: "IMOVEL",
  30: "CARRO",
  31: "MOTO",
  32: "CAMINHAO",
};

export {
  ProdutosEnum,
  tipoPlanoNome,
  TipoSimulacaoEnum,
  valoresSimulacao,
  CategoriaPagamento,
  TipoBotEnum,
  ProdutosNomes,
  BOT_NAME,
  categoryNames,
  CategoriasEnum,
};
