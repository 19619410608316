import React from "react";
import "./styles.scss";

const LoadingSpinner = ({ color }) => {
  return (
    <div
      data-testid="loading-spinner"
      className="spinner"
      style={{ borderColor: color }}
    ></div>
  );
};

export default LoadingSpinner;
