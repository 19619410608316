import React from "react";
import Slider from "react-slick";
import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomPrevArrow, CustomNextArrow } from "./arrow";
import LoadingDots from "../loadingDots";
import { CategoriasEnum, TipoSimulacaoEnum } from "../../utils/constants";
import useInitialConfigStore from "../../storeModules/useInitialConfigStore";

const getCategoryInfo = (categoriaID) => CategoriasEnum[categoriaID] || {};

const ServicesCarousel = ({ options, onOptionClick }) => {
  const tipoTabela = useInitialConfigStore.getState().tipoTabela;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const filteredOptions =
    tipoTabela === "completa"
      ? options.filter(
          (option) =>
            getCategoryInfo(option.categoriaID)?.tipoPlano ===
            TipoSimulacaoEnum.CREDITO_INTEGRAL
        )
      : tipoTabela === "paga_metade"
      ? options.filter(
          (option) =>
            getCategoryInfo(option.categoriaID)?.tipoPlano ===
            TipoSimulacaoEnum.CREDITO_PAGAMETADE
        )
      : tipoTabela === "integral"
      ? options.filter(
          (option) =>
            getCategoryInfo(option.categoriaID)?.tipoPlano ===
            TipoSimulacaoEnum.CREDITO_INTEGRAL
        )
      : options;

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {filteredOptions.map((option, index) => {
          const { title, description } = getCategoryInfo(option.categoriaID);
          const imageUrl = `https://chatbot-cdn.consorciosmycon.com.br/custom/categorias/${option.categoriaID}.svg`;

          const serviceImg = document.createElement("img");
          serviceImg.id = `carousel-img-${index}`;
          serviceImg.src = imageUrl;
          serviceImg.alt = title;
          serviceImg.width = 200;

          serviceImg.onload = () => {
            if (document.getElementById(`carousel-img-loading-${index}`)) {
              document.getElementById(`carousel-img-loading-${index}`).remove();
            }
            if (
              document.getElementById(`carousel-img-container-${index}`) &&
              !document.getElementById(`carousel-img-${index}`)
            ) {
              document
                .getElementById(`carousel-img-container-${index}`)
                .appendChild(serviceImg);
            }
          };

          return (
            <div key={index} className="carousel-item">
              <button
                onClick={() => {
                  onOptionClick(option);
                  option.action();
                }}
                className="services-carousel-button"
                type="button"
              >
                <div
                  className="services-carousel-image-container"
                  id={`carousel-img-container-${index}`}
                >
                  <LoadingDots
                    id={`carousel-img-loading-${index}`}
                    size="small"
                    color="primary"
                  />
                </div>
                <div className="options-title">
                  {title || "semtitulo"}
                  <div className="description-options">
                    {description || "Sem descrição"}
                  </div>
                </div>
              </button>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ServicesCarousel;
