import React from "react";
import "./styles.scss";
import useInitialConfigStore from "../../storeModules/useInitialConfigStore";

const LoadingDots = ({ size, color, id }) => {
  const colorBackground =
    useInitialConfigStore.getState().setThemeColors.primaryBackground;
  let classSize = "";

  if (size === "small") {
    classSize = "loading-dots--small";
  } else if (size === "medium") {
    classSize = "loading-dots--medium";
  } else if (size === "large") {
    classSize = "loading-dots--large";
  }

  return (
    <div
      id={id}
      data-testid="loading-dots"
      className={`loading-dots ${classSize} ${
        color === "primary"
          ? "loading-dots--color-primary"
          : "loading-dots--color-white"
      }`}
      style={{
        background:
          color === "primary"
            ? `radial-gradient(circle closest-side, ${colorBackground} 90%, #007bff00) 0 / calc(100% / 3) 100% space`
            : undefined,
      }}
    />
  );
};

export default LoadingDots;
