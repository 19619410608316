import Header from "../src/components/header/header";
import ChatBot from "../src/screens/bot/bot";
import { useEffect } from "react";
import useInitialConfigStore from "./storeModules/useInitialConfigStore";
import GoogleTagManager from "./components/gtm/gtm";
import useConsorcioStore from "./storeModules/useConsorcioStore";
import { TipoSimulacaoEnum } from "./utils/constants";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { set } from "react-hook-form";

function App(props) {
  const { title, description, indexar, gtmId, siteDomain, empresaId } = props;

  const { setInitialConfigStore, bannerSrc } = useInitialConfigStore();
  const { setConsorcioStore } = useConsorcioStore();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const referralcodeFromUrl = urlParams.get("referralcode");
  const idc = urlParams.get("idc");

  const isApp = window.location.href.includes("app");
  const canonicalUrl = window.location.href;

  useEffect(() => {
    if (isApp) {
      setInitialConfigStore(true, "isApp");
    }

    const referralcode =
      referralcodeFromUrl && referralcodeFromUrl !== "null"
        ? referralcodeFromUrl
        : localStorage.getItem("referralcode");

    const campanha = referralcode ? 605 : props.campanhaId;
    const compra = referralcode ? 130 : props.compraId;
    const empresa = referralcode ? 58 : props.empresaId;
    const desconto = referralcode ? true : props.desconto;
    const tipoTabela = referralcode ? "tabelaintegral" : props.tipoTabela;
    const brand = referralcode ? "mycon" : props.brand;

    const siteDomain = props.siteDomain;
    const navBar = props.navBar;
    const gtmId = props.gtmId;
    const linkWhatsApp = props.linkWhatsApp;
    const setThemeColors = {
      backgroundColor: props.backgroundColor,
      primaryBackground: props.primaryBackground,
      primaryForeground: props.primaryForeground
    };
 
    document.body.style.backgroundColor = setThemeColors.backgroundColor;
   
    const banner = referralcode
      ? "https://chatbot-cdn.mycon.com.br/mycon/chat-conecta-junho-2024.png"
      : props.bannerSrc;
    const tipoPlano = referralcode
      ? TipoSimulacaoEnum.CREDITO_INTEGRAL
      : props.tipoPlano;
    const botType = referralcode ? "MYCOINS" : props.botType;

    setInitialConfigStore(campanha, "campanhaId");
    setInitialConfigStore(compra, "compraId");
    setInitialConfigStore(empresa, "empresaId");
    setInitialConfigStore(desconto, "desconto");
    setInitialConfigStore(tipoTabela, "tipoTabela");
    setInitialConfigStore(brand, "brand");
    setInitialConfigStore(navBar, "navBar");
    setInitialConfigStore(siteDomain, "siteDomain");
    setInitialConfigStore(gtmId, "gtmId");
    setInitialConfigStore(setThemeColors, "setThemeColors");
    setInitialConfigStore(banner, "bannerSrc");
    setConsorcioStore(tipoPlano, "tipoPlano");
    setInitialConfigStore(botType, "botType");
    setInitialConfigStore(linkWhatsApp, "linkWhatsApp");

    if (referralcode) {
      setInitialConfigStore(referralcode, "referralcode");

      if (referralcodeFromUrl && referralcodeFromUrl !== "null") {
        localStorage.setItem("referralcode", referralcodeFromUrl);
      }
    }

    if (idc) {
      setInitialConfigStore(idc, "campanhaId");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content={indexar} />
        <meta itemprop="url" content={canonicalUrl} />

        <meta itemprop="name" content={title} />
        <meta itemprop="url" content={siteDomain} />
        <meta itemprop="image" content={`https://chatbot-cdn.mycon.com.br/custom/opengraph/${empresaId}.jpg`} />
    
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="myconsorcio" />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={siteDomain} />
        <meta property="og:image" content={`https://chatbot-cdn.mycon.com.br/custom/opengraph/${empresaId}.jpg`} />
      </Helmet>
      <GoogleTagManager gtmId={gtmId} />
      {!isApp && <Header />}
      <ChatBot bannerSrc={bannerSrc} />
    </div>
  );
}

export default App;
