import "./styles.scss";
import Header from "../../components/header/header";

const ErrorPage = () => {
  return (
    <div>
      <Header />
      <div className="error-page">
        <div className="error-page__container">
          <div className="error-page__grid">
            <div className="error-page__infos">
              <img
                src="https://chabot-cdn.consorciosmycon.com.br/custom/imagens/error-404.webp"
                alt="imagem de erro com código 404"
                className="error-page__img-error"
              />
              <h5 className="error-page__contrast">
                Desculpe, mas a página que está procurando não existe ou está
                inativa.
              </h5>
              <span className="error-page__text">
                Confira outros conteúdos que podem ser úteis:
              </span>
              <ul className="error-page__list">
                <li className="error-page__list-item">
                  <a
                    className="error-page__link"
                    targe="_blank"
                    href="https://www.mycon.com.br/tudo-sobre-consorcio"
                  >
                    O que é consórcio?
                  </a>
                </li>
                <li className="error-page__list-item">
                  <a
                    className="error-page__link"
                    targe="_blank"
                    href="https://www.mycon.com.br/o-que-comprar"
                  >
                    O que comprar?
                  </a>
                </li>
                <li className="error-page__list-item">
                  <a
                    className="error-page__link"
                    targe="_blank"
                    href="https://www.mycon.com.br/como-comprar"
                  >
                    Como comprar?
                  </a>
                </li>
                <li className="error-page__list-item">
                  <a
                    className="error-page__link"
                    targe="_blank"
                    href="https://www.mycon.com.br/precisa-de-ajuda"
                  >
                    Precisa de ajuda?
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="error-page__grid error-page__img-container">
            <img
              className="error-page__img"
              src="https://chabot-cdn.consorciosmycon.com.br/custom/imagens/person.webp"
              alt="imagem de uma mulher com dúvida"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
