import sendTag from "../../../../utils/sendTag";
import useBotControlStore from "../../../../storeModules/useBotControlStore";
import findStep from "../findStep";
import { getPaymentBoleto } from "../../../../api/requests";
import useUserStore from "../../../../storeModules/useUserStore";
import useConsorcioStore from "../../../../storeModules/useConsorcioStore";
import useInitialConfigStore from "../../../../storeModules/useInitialConfigStore";

const handleGetPaymentBoleto = () => {
  getPaymentBoleto();
};

const pagamentoBoletoSteps = [
  {
    name: "pagamento-boleto-registro",
    sender: "bot",
    text: "Certo! Estou <b>registrando</b> o seu boleto",
    action: async () => {
      handleGetPaymentBoleto();
    },
    component: (
      <img
        className="card-payment"
        src="https://chatbot-cdn.mycon.com.br/consorcio-digital/cards/assets/gerando-boleto.gif"
        alt="Pix Aprovado"
      />
    ),
  },
  {
    name: "payment-congratulations",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const basicUserName = useUserStore.getState().basic.nome;
      return `Prontinho, ${basicUserName}! Parabéns por iniciar a sua conquista!`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-boleto-options").index,
      });
    },
  },
  {
    name: "payment-boleto-options",
    sender: "bot",
    text: "Seleciona uma das opções abaixo para <b>realizar o pagamento</b>:",
    options: [
      {
        text: "COPIAR CÓDIGO",
        action: async () => {
          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "Boleto Bancário > Copia e Cola",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("payment-boleto-bar-code-message").index,
          });
        },
      },
      {
        text: "ABRIR BOLETO",
        action: async () => {
          const boletoUrl = useUserStore.getState().payment.boleto.boletoURL;

          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "Boleto Bancário > Abrir Boleto",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });

          if (boletoUrl) {
            window.open(boletoUrl, "_blank");

            useBotControlStore.setState({
              currentStep: findStep("payment-open-bank-app").index,
            });
          }
        },
      },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-boleto-bar-code-message",
    sender: "bot",
    text: "Este é o <b>código</b> de barras do <b>boleto</b>:",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-boleto-bar-code-show").index,
      });
    },
  },
  {
    name: "payment-boleto-bar-code-show",
    sender: "bot",
    text: () => {
      return useUserStore.getState().payment.boleto.boletoCodigoBarras;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-boleto-bar-code-copy").index,
      });
    },
  },
  {
    name: "payment-boleto-bar-code-copy",
    sender: "bot",
    text: "<b>Clique</b> no botão para <b>copiar o código</b> do boleto para <b>pagar</b> a sua primeira parcela.",
    options: [
      {
        text: "COPIAR CÓDIGO",
        action: () => {
          navigator.clipboard.writeText(
            useUserStore.getState().payment.boleto.boletoCodigoBarras
          );

          useBotControlStore.setState({
            currentStep: findStep("payment-open-bank-app").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-open-bank-app",
    sender: "bot",
    text: "Agora entre no <b>APP do seu banco</b> e faça o <b>pagamento</b> o quanto antes ;)",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-assembly").index,
      });
    },
  },
  {
    name: "payment-assembly",
    sender: "bot",
    text: "Assim você já participa da <b>próxima assembleia</b> e quem sabe, já pode ser um dos <b>contemplados</b>.",
    options: [
      {
        text: "CONTINUAR",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("compra-finalizada").index,
          });
        },
      },
    ],
  },
  {
    name: "pagamento-boleto-tentar-novamente",
    sender: "bot",
    text: `<b>OPS! </b>Tivemos um problema para gerar o BOLETO! O quê você deseja fazer?`,
    options: () => {
      return useConsorcioStore
        .getState()
        .fetchedProposta.formasPagamentoDisponiveis.filter((code) =>
          [5, 0, 1].includes(code)
        )
        .map((code) => {
          const options = {
            5: {
              text: "PAGAR COM PIX",
              action: () => {
                sendTag({ event: "PIX" });

                useBotControlStore.setState({
                  currentStep: findStep("pagamento-pix-registro").index,
                });
              },
            },
            0: {
              text: "PAGAR COM CARTÃO",
              action: () => {
                sendTag({ event: "Cartão de Crédito" });

                useBotControlStore.setState({
                  currentStep: findStep("payment-credit-card-message-secure")
                    .index,
                });
              },
            },
            1: {
              text: "TENTAR NOVAMENTE",
              action: () => {
                useBotControlStore.setState({
                  currentStep: findStep("pagamento-boleto-registro").index,
                });
              },
            },
          };
          return options[code];
        })
        .concat([
          {
            text: "FALAR COM NOSSOS ESPECIALISTAS",
            action: () => {
              const linkWhatsApp = useInitialConfigStore.getState().linkWhatsApp;
              window.open(
                linkWhatsApp,
                "_blank"
              );
            },
          },
        ]);
    },
  },
];

export default pagamentoBoletoSteps;
