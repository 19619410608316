import sendTag from "../../../../utils/sendTag";
import useBotControlStore from "../../../../storeModules/useBotControlStore";
import findStep from "../findStep";
import useConsorcioStore from "../../../../storeModules/useConsorcioStore";
import useInitialConfigStore from "../../../../storeModules/useInitialConfigStore";
import useUserStore from "../../../../storeModules/useUserStore";

const pagamentoCartaoSteps = [
  {
    name: "payment-credit-card-message-secure",
    sender: "bot",
    text: "Pagar no cartão é rápido e <b>100% seguro.</b>",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-credit-card-message-parcel").index,
      });
    },
  },
  {
    name: "payment-credit-card-message-parcel",
    sender: "bot",
    text: "Você pode pagar em <b>até 3x sem juros :)</b>",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-credit-card-message-parcel-value").index,
      });
    },
  },
  {
    name: "payment-credit-card-message-parcel-value",
    sender: "bot",
    text: () => {
      const parcela =
        useConsorcioStore.getState().selectedPlano.planoValorPagamento;

      return `O pagamento será realizado no valor de ${parcela.toLocaleString(
        "pt-BR",
        { style: "currency", currency: "BRL" }
      )}.`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-credit-card-select").index,
      });
    },
  },
  {
    name: "payment-credit-card-select",
    sender: "bot",
    text: "Escolha uma das opções",
    options: [
      {
        text: "PAGAR NO CARTÃO",
        action: () => {
          useBotControlStore.setState({
            showPaymentModal: true,
          });

          sendTag({
            event: "Dados Cartão Crédito",
            metodo_pagamento: "cartao_de_credito",
          });
        },
      },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-processing",
    sender: "bot",
    text: "Aguarde um momento enquanto processamos o seu pagamento.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-success-dream").index,
      });
    },
  },
  {
    name: "payment-success-dream",
    sender: "bot",
    text: "Prontinho! Estamos felizes em fazer parte do seu sonho.",
  },
  {
    name: "payment-credit-card-pending",
    sender: "bot",
    text: "<b>Seu pagamento cartão está em processamento.</b> Por favor, aguarde a confirmação que será enviada para o seu e-mail.",
    action: async () => {
      const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
      const purchaseBemCredito =
        useConsorcioStore.getState().selectedPlano.bemCredito;
      const purchaseBemNome =
        useConsorcioStore.getState().selectedPlano.bemNome;
      const purchasePlanoMeses =
        useConsorcioStore.getState().selectedPlano.planoMeses;
      const purchaseContrato =
        useConsorcioStore.getState().fetchedProposta.contrato;
      const purchaseCidade = useUserStore.getState().contrato.cidade;
      const purchaseIDC = useInitialConfigStore.getState().campanhaId;
      const purchaseBrand = useInitialConfigStore.getState().brand;

      sendTag({
        event: "purchase",
        ecommerce: {
          transaction_id: purchaseContrato, //Número do Contrato
          affiliation: purchaseBrand,
          value: purchaseBemCredito, //Valor do Crédito
          currency: "BRL",
          payment_type: "Cartão de Crédito",
          idc_compra: purchaseIDC,
          items: [
            {
              item_name: purchaseBemNome, //Nome do Bem
              item_id: purchaseCategoriaId, //ID daCategoria
              price: purchaseBemCredito, //Valor do Crédito
              item_brand: purchaseBrand,
              plano_meses: purchasePlanoMeses, //Número de Parcela
              location_id: purchaseCidade,
              index: 1,
              quantity: 1,
            },
          ],
        },
      });

      useBotControlStore.setState({
        currentStep: findStep("compra-finalizada").index,
      });
    },
  },

  {
    name: "payment-refused",
    sender: "bot",
    text: "<b>Pagamento recusado :(</b> <br/><br/> O quê você deseja fazer?",
    options: () => {
      return useConsorcioStore
        .getState()
        .fetchedProposta.formasPagamentoDisponiveis.filter((code) =>
          [5, 0, 1].includes(code)
        )
        .map((code) => {
          const options = {
            5: {
              text: "PAGAR COM PIX",
              action: () => {
                sendTag({ event: "PIX" });

                useBotControlStore.setState({
                  currentStep: findStep("pagamento-pix-registro").index,
                });
              },
            },
            0: {
              text: "TENTAR NOVAMENTE",
              action: () => {
                sendTag({ event: "Cartão de Crédito" });

                useBotControlStore.setState({
                  currentStep: findStep("payment-credit-card-message-secure")
                    .index,
                });
              },
            },
            1: {
              text: "PAGAR COM BOLETO",
              action: () => {
                sendTag({ event: "Boleto Bancário" });

                useBotControlStore.setState({
                  currentStep: findStep("pagamento-boleto-registro").index,
                });
              },
            },
          };
          return options[code];
        })
        .concat([
          {
            text: "FALAR COM NOSSOS ESPECIALISTAS",
            action: () => {
              const linkWhatsApp = useInitialConfigStore.getState().linkWhatsApp;
              window.open(
                linkWhatsApp,
                "_blank"
              );
            },
          },
        ]);
    },
    component: (
      <img
        className="card-payment"
        src="https://chatbot-cdn.mycon.com.br/consorcio-digital/cards/assets/cartao-recusado.gif"
        alt="Cartão recusado"
      />
    ),
  },
  {
    name: "payment-pending",
    sender: "bot",
    text: "<b>Seu pagamento está sendo validado :)</b> <br><br> Aguarde a confirmação que será enviada para o seu e-mail. Qualquer dúvida, entre em contato com nosso time de especialistas.",
    options: [
      {
        text: "FALAR COM NOSSOS ESPECIALISTAS",
        action: () => {
          const linkWhatsApp = useInitialConfigStore.getState().linkWhatsApp;
          window.open(
            linkWhatsApp,
            "_blank"
          );
        },
      },
    ],
  },
  {
    name: "payment-success",
    sender: "bot",
    text: () => {
      const { cartaoAutorizacao } = useUserStore.getState().payment;
      return `<b>Seu pagamento foi realizado com sucesso!</b> <br><br> nº ${cartaoAutorizacao}`;
    },
    action: async () => {
      const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
      const purchaseBemCredito =
        useConsorcioStore.getState().selectedPlano.bemCredito;
      const purchaseBemNome =
        useConsorcioStore.getState().selectedPlano.bemNome;
      const purchasePlanoMeses =
        useConsorcioStore.getState().selectedPlano.planoMeses;
      const purchaseContrato =
        useConsorcioStore.getState().fetchedProposta.contrato;
      const purchaseCidade = useUserStore.getState().contrato.cidade;
      const purchaseIDC = useInitialConfigStore.getState().campanhaId;
      const purchaseBrand = useInitialConfigStore.getState().brand;

      sendTag({
        event: "purchase",
        ecommerce: {
          transaction_id: purchaseContrato, //Número do Contrato
          affiliation: purchaseBrand,
          value: purchaseBemCredito, //Valor do Crédito
          currency: "BRL",
          payment_type: "Cartão de Crédito",
          idc_compra: purchaseIDC,
          items: [
            {
              item_name: purchaseBemNome, //Nome do Bem
              item_id: purchaseCategoriaId, //ID daCategoria
              price: purchaseBemCredito, //Valor do Crédito
              item_brand: purchaseBrand,
              plano_meses: purchasePlanoMeses, //Número de Parcela
              location_id: purchaseCidade,
              index: 1,
              quantity: 1,
            },
          ],
        },
      });

      useBotControlStore.setState({
        currentStep: findStep("compra-finalizada").index,
      });
    },
  },
];

export default pagamentoCartaoSteps;
