const baseApiURL = process.env.REACT_APP_API_URL;

const healthyCheckApi = "/hc";
const getCategoriaApi = "/api/categoria";
const saveLeadApi = "/api/lead";
const plansApi = "/api/plano";
const cepApi = "/api/endereco";
const getPropostaApi = "/api/proposta";
const pagamentoCartaoApi = "/api/pagamento/cartao";
const pagamentoBoletoApi = "/api/pagamento/boleto";
const pagamentoPixApi = "/api/pagamento/pix";
const planDetailsApi = "/api/plano/detalhes";
const forbiddenWords = "/api/lead/validar-palavras";

export {
  baseApiURL,
  healthyCheckApi,
  getCategoriaApi,
  saveLeadApi,
  plansApi,
  cepApi,
  getPropostaApi,
  pagamentoCartaoApi,
  pagamentoBoletoApi,
  pagamentoPixApi,
  planDetailsApi,
  forbiddenWords,
};
