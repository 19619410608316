import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./screens/errorPage";
import offers from "./config/offers";

const hostname = window.location.hostname;

const isDomainMatch = (offer) => {
  return offer.siteDomain.includes(hostname);
};

const getRouteElement = (offer) => {
  if (hostname === "localhost" || hostname.includes("chatbot-dev")) {
    return <App {...offer} />;
  }

  return isDomainMatch(offer) ? <App {...offer} /> : <ErrorPage />;
};

const getOfferByHostname = () => {
  if (hostname === "localhost" || hostname.includes("chatbot-dev")) {
    return offers.HOME;
  }

  return Object.values(offers).find((offer) =>
    offer.siteDomain.includes(hostname)
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: getRouteElement(getOfferByHostname()),
  },
  {
    path: "/app",
    element: getRouteElement(offers.APP),
  },
  {
    path: "/performance",
    element: getRouteElement(offers.PERFORMANCE),
  },
  {
    path: "/oferta",
    element: getRouteElement(offers.OFERTA),
  },
  {
    path: "/abandono-imovel",
    element: getRouteElement(offers.ABANDONO_IMOVEL),
  },
  {
    path: "/99",
    element: getRouteElement(offers.COLLAB_99),
  },
  {
    path: "/99-pay",
    element: getRouteElement(offers.COLLAB_99_PAY),
  },
  {
    path: "/rota99",
    element: getRouteElement(offers.COLLAB_99_ROTA99),
  },
  {
    path: "/premmia",
    element: getRouteElement(offers.COLLAB_PREMMIA),
  },
  {
    path: "/gazeta",
    element: getRouteElement(offers.COLLAB_GAZETA),
  },
  {
    path: "/salary-fits",
    element: getRouteElement(offers.COLLAB_SALARY_FITS),
  },
  {
    path: "/kmv",
    element: getRouteElement(offers.COLLAB_KMV),
  },
  {
    path: "/livelo",
    element: getRouteElement(offers.COLLAB_LIVELO),
  },
  {
    path: "/sem-parar",
    element: getRouteElement(offers.COLLAB_SEM_PARAR),
  },
  {
    path: "/finanzero",
    element: getRouteElement(offers.COLLAB_FINANZERO),
  },
  {
    path: "/clube-certo",
    element: getRouteElement(offers.COLLAB_CLUBE_CERTO),
  },
  {
    path: "/clube-giro",
    element: getRouteElement(offers.COLLAB_CLUBE_GIRO),
  },
  {
    path: "/easy-credito",
    element: getRouteElement(offers.COLLAB_EASY_CREDITO),
  },
  {
    path: "/auto-mais",
    element: getRouteElement(offers.COLLAB_AUTO_MAIS),
  },
  {
    path: "/auto-mais-app",
    element: getRouteElement(offers.COLLAB_AUTO_MAIS_APP),
  },
  {
    path: "/trucadao",
    element: getRouteElement(offers.COLLAB_TRUCADAO),
  },
  {
    path: "/stopclub",
    element: getRouteElement(offers.COLLAB_STOPCLUB),
  },
  {
    path: "*", 
    element: <ErrorPage />,
  },
]);

export default router;
