import useInitialConfigStore from "../../../storeModules/useInitialConfigStore";
import findStep from "./findStep";
import useBotControlStore from "../../../storeModules/useBotControlStore";
import useUserStore from "../../../storeModules/useUserStore";
import useConsorcioStore from "../../../storeModules/useConsorcioStore";
import sendTag from "../../../utils/sendTag";
import { getPlans, getCep, getProposta } from "../../../api/requests";
import sha256 from "crypto-js/sha256";
import { ProdutosNomes, tipoPlanoNome } from "../../../utils/constants";
import addCepMask from "../../../utils/addCepMask";
import addPhoneMask from "../../../utils/addPhoneMask";
import GroupInfoOptions from "../../../components/group-info/group-info";
import addPhoneDatalayerMask from "../../../utils/addPhoneDatalayerMask";
import dayjs from "dayjs";
import { validateForbiddenWords } from "../../../api/requests";

const isSimulateAgain = () => {
  return useUserStore.getState().isSimulateAgain;
};

const handleGetPlans = () => {
  getPlans();
};

const handleGetProposta = () => {
  getProposta();
};

export const handleGetCep = (errorAt) => {
  useUserStore.setState({
    cepErrorAt: errorAt,
  });
  getCep();
};

export const sendDataLayerUserDataUpdate = () => {
  const {
    tipoCliente,
    cpfCnpj,
    tipoPessoa,
    nascimentoFundacao,
    nomeRazao,
    cep,
  } = useUserStore.getState().contrato;
  const { email, telefone } = useUserStore.getState().basic;

  const isPessoaFisica = tipoPessoa === 0;

  const hashedEmail = sha256(email).toString();
  const hashedTelefone = sha256(addPhoneDatalayerMask(telefone)).toString();
  const hashCpfCnpj = sha256(cpfCnpj).toString();

  sendTag({
    event: "Dados Cadastrais Atualizados",
    tipo_cliente: tipoCliente,
    email_hash: hashedEmail,
    celular_hash: hashedTelefone,
    cpf_hash: isPessoaFisica ? hashCpfCnpj : "",
    cnpj_hash: isPessoaFisica ? "" : hashCpfCnpj,
    email_cliente: email,
    telefone_cliente: addPhoneDatalayerMask(telefone),
    data_nascimento: isPessoaFisica ? nascimentoFundacao : "",
    data_fundacao: isPessoaFisica ? "" : nascimentoFundacao,
    nome_completo: isPessoaFisica ? nomeRazao : "",
    razao_social: isPessoaFisica ? "" : nomeRazao,
    cep: cep,
  });
};

const proposalSteps = [
  {
    name: "proposal-plans-list-carousel-get",
    sender: "bot",
    text: () => {
      return isSimulateAgain() ? null : "Obrigado!";
    },
    isWaitingForFetch: true,
    action: () => {
      handleGetPlans();
    },
  },
  {
    name: "plans-list-select",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `${userBasicName}! selecionei os <b>melhores planos</b> pra você. 😀`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("plans-list-carousel-show-message").index,
      });
    },
  },
  {
    name: "plans-list-carousel-show-message",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => "Selecione o plano ideal para você:",
    useCarouselPlan: true,
  },
  {
    name: "parcela-desconto-mensagem",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      const userTipoBem = useConsorcioStore.getState().tipoBem;
      const userTipoBemNome = ProdutosNomes[userTipoBem];
      const { empresaId } = useInitialConfigStore.getState();

      const isBlackFriday = empresaId === 97;

      return `<b>${userBasicName}!</b> olha que bacana! Como prometido, eu vou pagar <b>${
        isBlackFriday ? "60%" : "50%"
      } da 1ª parcela</b> desse plano que você escolheu: <b>Consórcio para ${userTipoBemNome}</b>`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("parcela-desconto-valor").index,
      });
    },
  },
  {
    name: "parcela-desconto-valor",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userValorPagamento =
        useConsorcioStore.getState().selectedPlano.planoValorPagamento;

      return `Nessa primeira parcela, você pagará apenas: <b>${userValorPagamento.toLocaleString(
        "pt-BR",
        { style: "currency", currency: "BRL" }
      )}</b>`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("proposal-confirm-infos").index,
      });
    },
  },
  {
    name: "proposal-confirm-infos",
    sender: "bot",
    text: "Falta pouco para <b>iniciar a sua conquista</b>. Só preciso confirmar algumas informações. 😎",
    options: [
      {
        text: "SEGUIR EM FRENTE",
        action: async () => {
          const userBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const userBemID = useConsorcioStore.getState().selectedPlano.bemID;
          const userPlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const userValorPagamento =
            useConsorcioStore.getState().selectedPlano.planoValorPagamento;
          const userValorParcela =
            useConsorcioStore.getState().selectedPlano.planoValorParcela;
          const TaxaAdmTotal =
            useConsorcioStore.getState().fetchedPlanDetails.taxaAdmTotal;

          sendTag({
            event: "Vamos Fechar",
            valor_credito: userBemCredito, //Valor do Crédito
            bem_id: userBemID, //ID do Bem
            plano_meses: userPlanoMeses, //Número de Parcela
            valor_pagamento: userValorPagamento, //Valor primeira parcela
            valor_parcela: userValorParcela, //Valor demais parcelas
            taxa_total: TaxaAdmTotal, //Valor demais parcelas
          });

          useBotControlStore.setState({
            currentStep: findStep("proposal-plan-holder").index,
          });
        },
      },
      {
        text: "VER OUTROS PLANOS",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-plans-list-carousel-get").index,
          });

          sendTag({ event: "Simular Outro" });
        },
      },
    ],
  },
  {
    name: "proposal-plan-holder",
    sender: "bot",
    text: "Quem será o titular do plano?",
    options: [
      {
        text: "EU MESMO",
        action: () => {
          sendTag({ event: "Pessoa Fisica" });

          const userData = useUserStore.getState();
          const userContratoData = useUserStore.getState().contrato;

          useUserStore.setState({
            ...userData,
            contrato: {
              ...userContratoData,
              tipoPessoa: 0,
              tipoCliente: "Pessoa Física",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("proposal-add-name-razao").index,
          });
        },
      },
      {
        text: "MINHA EMPRESA",
        action: () => {
          sendTag({ event: "Pessoa Juridica" });

          const userData = useUserStore.getState();
          const userContratoData = useUserStore.getState().contrato;

          useUserStore.setState({
            ...userData,
            contrato: {
              ...userContratoData,
              tipoPessoa: 1,
              tipoCliente: "Pessoa Jurídica",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("proposal-add-name-razao").index,
          });
        },
      },
    ],
  },
  {
    name: "proposal-add-name-razao",
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Qual o seu <b>nome completo</b>?"
        : "Digite a <b>razão social</b>";
    },
    inputType: "userContratoNomeRazao",
    action: async () => {
      const { tipoPessoa } = useUserStore.getState().contrato;
      const isPessoaFisica = tipoPessoa === 0;

      const propName = isPessoaFisica ? "nome" : "razao_social";
      const propNameCompleta = isPessoaFisica
        ? "nome_completo"
        : "razao_social_completa";

      const userNomeRazao = useUserStore.getState().contrato.nomeRazao;
      const hashNomeRazao = sha256(userNomeRazao).toString();

      const data = await validateForbiddenWords(
        useUserStore.getState().contrato.nomeRazao,
        `Por favor, insira ${
          isPessoaFisica ? "um nome válido." : "uma razaão social válida."
        }`,
        "proposal-add-cpf-cnpj",
        "userContratoNomeRazao"
      );

      if (data.success) {
        sendTag({
          event: isPessoaFisica
            ? "Cadastro Nome Completo"
            : "Cadastro Razão Social",
          [propName]: hashNomeRazao,
          [propNameCompleta]: userNomeRazao,
        });
      }

      return data;
    },
  },
  {
    name: "proposal-add-cpf-cnpj",
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Informe o seu <b>CPF </b>"
        : "Informe o <b>CNPJ </b>";
    },
    inputType: "userCpfCnpj",
    action: async () => {
      const { tipoPessoa } = useUserStore.getState().contrato;
      const isPessoaFisica = tipoPessoa === 0;
      const userCpfCnpj = useUserStore.getState().contrato.cpfCnpj;
      const hashCpfCnpj = sha256(userCpfCnpj).toString();
      const propName = isPessoaFisica ? "cpf" : "cnpj";

      sendTag({
        event: `Cadastro ${isPessoaFisica ? "CPF" : "CNPJ"}`,
        [propName]: hashCpfCnpj,
      });

      useBotControlStore.setState({
        currentStep: findStep("proposal-add-cep").index,
      });
    },
  },
  {
    name: "proposal-add-cep",
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `<b>${userBasicName}</b>, qual é o seu CEP?`;
    },
    inputType: "userCep",
    action: () => {
      handleGetCep("cep-first-input");
    },
  },
  {
    name: "proposal-thanks-message",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `Obrigado! <b>${userBasicName}</b>`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("proposal-wait-data-register").index,
      });
    },
  },
  {
    name: "proposal-wait-data-register",
    sender: "bot",
    text: "Enquanto eu registro os seus dados...",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("proposal-add-date").index,
      });
    },
  },
  {
    name: "proposal-add-date",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Me informe a sua <b>data de nascimento</b>, por favor:"
        : "Me informe a sua <b>data de fundação</b> da empresa, por favor:";
    },
    inputType: "userNascimentoFundacao",
    action: async () => {
      const userNascimentoFundacao =
        useUserStore.getState().contrato.nascimentoFundacao;

      sendTag({
        event: "Cadastro Nascimento",
        nascimento: userNascimentoFundacao,
      });

      useBotControlStore.setState({
        currentStep: findStep("proposal-confirm-data").index,
      });
    },
  },
  {
    name: "proposal-confirm-data",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `${userBasicName}, agora é só <b>confirmar</b> se está tudo certo:`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("proposal-show-data").index,
      });
    },
  },
  {
    name: "proposal-show-data",
    sender: "bot",
    action: () => {
      const botMessages = useBotControlStore.getState().messages;
      const botCurrentStep = useBotControlStore.getState().currentStep;
      const consorcioBem = useConsorcioStore.getState().tipoBem;
      const userTipoBemNome = ProdutosNomes[consorcioBem];
      const consorcioTipoPlano = useConsorcioStore.getState().tipoPlano;
      const selectedPlano = useConsorcioStore.getState().selectedPlano;
      const TaxaAdmTotal =
        useConsorcioStore.getState().fetchedPlanDetails.taxaAdmTotal;
      const PlanoMeses = useConsorcioStore.getState().selectedPlano.planoMeses;
      const taxaMensal = (TaxaAdmTotal / PlanoMeses).toFixed(4);
      const exibirTextoResolucao285 =
        useConsorcioStore.getState().selectedPlano.exibirTextoResolucao285;

      const newMessage = {
        sender: "bot",
        text: `
          <b>Consórcio para ${userTipoBemNome}</b><br /><br />
          <b>Tipo:</b> ${tipoPlanoNome[consorcioTipoPlano]}<br />
          <b> Plano:</b> ${selectedPlano.bemCredito.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}<br />
          em ${
            selectedPlano.planoMeses
          } parcelas de ${selectedPlano.planoValorParcela.toLocaleString(
          "pt-BR",
          { style: "currency", currency: "BRL" }
        )}<br />
          <b>Taxa mensal:</b> ${taxaMensal}%
        `,
      };

      useBotControlStore.setState({
        currentStep: botCurrentStep,
        messages: [...botMessages, newMessage],
      });

      useBotControlStore.setState({
        currentStep: findStep(
          exibirTextoResolucao285
            ? "proposal-285-message"
            : "proposal-show-suymmmary"
        ).index,
      });
    },
  },
  {
    name: "proposal-285-message",
    sender: "bot",
    text: () => {
      const assemblyDate =
        useConsorcioStore.getState().selectedPlano.dataAssembleia;
      return `<b>Elegível para participação na assembleia do dia ${dayjs(
        assemblyDate
      ).format("DD/MM/YYYY")}</b>.`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("proposal-show-suymmmary").index,
      });
    },
  },
  {
    name: "proposal-show-suymmmary",
    sender: "bot",
    action: () => {
      const botMessages = useBotControlStore.getState().messages;
      const botCurrentStep = useBotControlStore.getState().currentStep;
      const { nomeRazao, cpfCnpj, nascimentoFundacao, cep } =
        useUserStore.getState().contrato;
      const { telefone, email } = useUserStore.getState().basic;

      const { tipoPessoa } = useUserStore.getState().contrato;
      const isPessoaFisica = tipoPessoa === 0;

      const nomeRazaoLabel = isPessoaFisica ? "Nome" : "Razão Social";
      const cpfCnpjLabel = isPessoaFisica ? "CPF" : "CNPJ";
      const nascimentoFundacaoLabel = isPessoaFisica
        ? "Nascimento"
        : "Fundação";

      const newMessage = {
        sender: "bot",
        text: `
          <div class="message-ellipsis">
            <b> ${nomeRazaoLabel}:</b> ${nomeRazao}<br />
            <b> E-mail:</b> ${email}<br />
            <b> Celular:</b> ${addPhoneMask(telefone)}<br />
            <b> ${cpfCnpjLabel}:</b> ${cpfCnpj}<br />
            <b> ${nascimentoFundacaoLabel}:</b> ${nascimentoFundacao}<br />
            <b> CEP:</b> ${addCepMask(cep)}
          </div>
        `,
        title: `${nomeRazao} | ${email}`,
      };

      useBotControlStore.setState({
        currentStep: botCurrentStep,
        messages: [...botMessages, newMessage],
      });

      useBotControlStore.setState({
        currentStep: findStep("proposal-terms").index,
      });
    },
  },
  {
    name: "proposal-terms",
    sender: "bot",
    text: () => {
      const { empresaId, desconto } = useInitialConfigStore.getState();

      return `
              Para finalizar, preciso que você <b>leia</b> e <b>aceite</b> os termos do ${
                desconto
                  ? `<b>Regulamento Geral do Consórcio</b> e <b>Regulamento da Campanha.</b>`
                  : "<b>Regulamento Geral do Consórcio.</b>"
              }<br/><br/>
              <a class="link-highlight" href="https://chabot-cdn.mycon.com.br/mycon/contrato-participacao-grupo-consorcio-por-adesao.pdf" target="_blank">Regulamento Geral do Consórcio</a>
              ${
                desconto
                  ? `<br/><br/><a class="link-highlight" href="https://chatbot-cdn.mycon.com.br/regulamentos/consorcio-digital/${empresaId}.pdf" target="_blank">Regulamento da Campanha</a>`
                  : ""
              }
            `;
    },
    options: [
      {
        text: "CONFIRMAR",
        action: () => {
          handleGetProposta();
        },
      },
      {
        text: "DETALHES DO PLANO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-confirm-plan").index,
          });
        },
      },
      {
        text: "ALTERAR DADOS",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-change-infos").index,
          });
        },
      },
      {
        text: "FALAR COM OS ESPECIALISTAS",
        action: () => {
          const linkWhatsApp = useInitialConfigStore.getState().linkWhatsApp;
          window.open(
            linkWhatsApp,
            "_blank"
          );
        },
      },
    ],
  },
  {
    name: "proposal-change-infos",
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `${userBasicName}, qual informação você precisa <b>alterar</b>?`;
    },
    options: [
      {
        text: "NOME",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-change-name").index,
          });
        },
      },
      {
        text: "E-MAIL",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-change-email").index,
          });
        },
      },
      {
        text: "CPF/CNPJ",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-change-cpf-cnpj").index,
          });
        },
      },
      {
        text: "CELULAR",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-change-phone").index,
          });
        },
      },
      {
        text: "NASCIMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-change-date").index,
          });
        },
      },
      {
        text: "CEP",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-change-cep").index,
          });
        },
      },
      {
        text: "NENHUMA",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-confirm-data").index,
          });
        },
      },
    ],
  },
  {
    name: "proposal-confirm-plan",
    sender: "bot",
    options: [{ text: "CONFIRMAR", action: () => handleGetProposta() }],
    action: () => {
      const botMessages = useBotControlStore.getState().messages;
      const planInfosMessage = {
        id: "plan-infos",
        sender: "bot",
        text: GroupInfoOptions(),
      };

      useBotControlStore.setState({
        messages: [...botMessages, planInfosMessage],
      });
    },
  },
  {
    name: "proposal-change-name",
    sender: "bot",
    text: "Altere o <b>nome completo</b> que vai no contrato.",
    inputType: "userContratoNomeRazao",
    action: () => {
      sendDataLayerUserDataUpdate();

      useBotControlStore.setState({
        currentStep: findStep("proposal-confirm-data").index,
      });
    },
  },
  {
    name: "proposal-wait",
    sender: "bot",
    text: "<b>Aguarde</b> um momento...",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("proposal-success").index,
      });
    },
  },
  {
    name: "proposal-success",
    sender: "bot",
    text: () => {
      const contrato = useConsorcioStore.getState().fetchedProposta.contrato;
      return `Pronto! Sua proposta nº <b>${contrato} foi efetivada com sucesso!</b>`;
    },
    action: async () => {
      const propostaPropostaID =
        useConsorcioStore.getState().fetchedProposta.propostaID;
      const propostaContrato =
        useConsorcioStore.getState().fetchedProposta.contrato;
      const propostaTipoCliente = useUserStore.getState().contrato.tipoCliente;

      sendTag({
        event: "Proposta Gerada",
        proposta_id: propostaPropostaID,
        contrato_id: propostaContrato,
        tipo_cliente: propostaTipoCliente,
      });

      useBotControlStore.setState({
        currentStep: findStep("payment-init").index,
      });
    },
  },
  {
    name: "proposal-buy-again",
    sender: "bot",
    text: "Você quer <b>comprar outra</b> cota?",
    options: [
      {
        text: "SIM",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("payment-buy-again-value").index,
          });
        },
      },
      {
        text: "NÃO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("pesquisa-inicio").index,
          });
        },
      },
    ],
  },
  {
    name: "proposal-change-email",
    sender: "bot",
    text: "Altere seu <b>e-mail</b>",
    inputType: "userEmail",
    action: () => {
      sendDataLayerUserDataUpdate();

      useBotControlStore.setState({
        currentStep: findStep("proposal-confirm-data").index,
      });
    },
  },
  {
    name: "proposal-change-cpf-cnpj",
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Altere o seu <b>CPF </b>"
        : "Altere o <b>CNPJ </b>";
    },
    inputType: "userCpfCnpj",
    action: () => {
      sendDataLayerUserDataUpdate();

      useBotControlStore.setState({
        currentStep: findStep("proposal-confirm-data").index,
      });
    },
  },
  {
    name: "proposal-change-phone",
    sender: "bot",
    text: "Altere seu <b>celular</b> (com DDD)",
    inputType: "userTelefone",
    action: () => {
      sendDataLayerUserDataUpdate();

      useBotControlStore.setState({
        currentStep: findStep("proposal-confirm-data").index,
      });
    },
  },
  {
    name: "proposal-change-date",
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Altere a <b>data de nascimento</b>:"
        : "Altere a <b>data de fundação</b>:";
    },
    inputType: "userNascimentoFundacao",
    action: () => {
      sendDataLayerUserDataUpdate();

      useBotControlStore.setState({
        currentStep: findStep("proposal-confirm-data").index,
      });
    },
  },
  {
    name: "proposal-change-cep",
    sender: "bot",
    text: "Altere CEP:",
    inputType: "userCep",
    action: () => {
      handleGetCep("update");
      sendDataLayerUserDataUpdate();
    },
  },
];

export default proposalSteps;
