import { TipoSimulacaoEnum, BOT_NAME } from "../utils/constants";

const collabs = {
  COLLAB_99: {
    botType: BOT_NAME.COLLAB_99,
    title: "Mycon e 99 | Consórcio mais barato para motoristas de aplicativo.",
    description: "Compre seu carro em até 100x da forma mais barata hoje no Brasil. Créditos de 40 a 80 mil com as menores parcelas. Simule seu crédito!",
    indexar: "noindex,nofollow",
    campanhaId: 426,
    compraId: 120,
    empresaId: 48,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_99",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-99.mycon.com.br",
    backgroundColor: "#383838",
    primaryBackground: "#ffdd00",
    primaryForeground: "#000000",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/99-driver/chat-padrao-99-v082024.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=99%20Motoristas%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20humanos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_99_ROTA99: {
    botType: BOT_NAME.COLLAB_99_ROTA99,
    title: "Mycon e 99 | Consórcio mais barato para motoristas de aplicativo.",
    description: "Compre seu carro em até 100x da forma mais barata hoje no Brasil. Créditos de 40 a 80 mil com as menores parcelas. Simule seu crédito!",
    indexar: "noindex,nofollow",
    campanhaId: 426,
    compraId: 156,
    empresaId: 85,
    desconto: true,
    navBar: false,
    tipoTabela: "paga_metade",
    brand: "collab_rota_99",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-99.mycon.com.br/rota99",
    backgroundColor: "#383838",
    primaryBackground: "#ffdd00",
    primaryForeground: "#000000",
    bannerSrc: "https://chatbot-cdn.consorciosmycon.com.br/99-driver/99-Programa-Fidelidade%20-%208-min.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=99%20Motoristas%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20humanos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
  },
  COLLAB_99_PAY: {
    botType: BOT_NAME.COLLAB_99_PAY,
    title: "Mycon e 99Pay | Consórcio mais barato para passageiros.",
    description: "Compre sua casa em até 240x sem juros e sem entrada.",
    indexar: "noindex,nofollow",
    campanhaId: 472,
    compraId: 127,
    empresaId: 55,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_99_pay",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-99pay.mycon.com.br",
    backgroundColor: "#383838",
    primaryBackground: "#ffdd00",
    primaryForeground: "#000000",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/99-pay/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=99Pay%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_PREMMIA: {
    botType: BOT_NAME.COLLAB_PREMMIA,
    title: "Mycon e Premmia | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 629,
    compraId: 143,
    empresaId: 71,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_premmia",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-premmia.mycon.com.br",
    backgroundColor: "#008c4f",
    primaryBackground: "#FFBE26",
    primaryForeground: "#000000",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/premmia/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Premmia%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_GAZETA: {
    botType: BOT_NAME.COLLAB_GAZETA,
    title: "Mycon e A Gazeta | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 557,
    compraId: 131,
    empresaId: 59,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_gazeta",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-gazeta.mycon.com.br",
    backgroundColor: "#09213b",
    primaryBackground: "#65b0ff",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chatbot-cdn.consorciosmycon.com.br/gazeta/banner-gazeta-sem-promocional-min.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=A%20Gazeta%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_SALARY_FITS: {
    botType: BOT_NAME.COLLAB_SALARY_FITS,
    title: "Mycon e SalaryFits | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 565,
    compraId: 129,
    empresaId: 57,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_salary_fits",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-salaryfits.mycon.com.br",
    backgroundColor:  "#192137",
    primaryBackground: "#FFBE26",
    primaryForeground: "#000000",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/salary-fits/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Salaryfits%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_KMV: {
    botType: BOT_NAME.COLLAB_KMV,
    title: "Mycon e KM de Vantagens | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 533,
    compraId: 134,
    empresaId: 62,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_kmv",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-kmv.mycon.com.br",
    backgroundColor: "#0045b5",
    primaryBackground: "#FFDD00",
    primaryForeground: "#000000",
    bannerSrc: "https://chatbot-cdn.consorciosmycon.com.br/abastece-ai/chat-padrao-km-vantagens.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=KM%20de%20Vantagens%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_LIVELO: {
    botType: BOT_NAME.COLLAB_LIVELO,
    title: "Mycon e Livelo | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 702,
    compraId: 154,
    empresaId: 82,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_livelo",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-livelo.mycon.com.br",
    backgroundColor: "#190f55",
    primaryBackground: "#E02678",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/livelo/banner-livelo-32pontos-03-2025.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Livelo%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_SEM_PARAR: {
    botType: BOT_NAME.COLLAB_SEM_PARAR,
    title: "Mycon e Sem Parar | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 661,
    compraId: 147,
    empresaId: 75,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_sem_parar",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-semparar.mycon.com.br",
    backgroundColor: "#a8a8a8",
    primaryBackground: "#d60c52",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/sem-parar/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Sem%20Parar%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_FINANZERO: {
    botType: BOT_NAME.COLLAB_FINANZERO,
    title: "Mycon e FinanZero | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 680,
    compraId: 151,
    empresaId: 79,
    desconto: false,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_finanzero",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-finanzero.mycon.com.br",
    backgroundColor: "#018173",
    primaryBackground: "#06bcb4",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/finanzero/finanzero-chat-min.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=FinanZero%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_CLUBE_CERTO: {
    botType: BOT_NAME.COLLAB_CLUBE_CERTO,
    title: "Mycon e ClubeCerto | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 846,
    compraId: 165,
    empresaId: 94,
    desconto: true,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_clube_certo",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-clubecerto.mycon.com.br",
    backgroundColor: "#032f11",
    primaryBackground: "#00a24e",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chatbot-cdn.consorciosmycon.com.br/clube-certo/banner-clube-certo.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Clube%20Certo%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_CLUBE_GIRO: {
    botType: BOT_NAME.COLLAB_CLUBE_GIRO,
    title: "Mycon e Clube Giro | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 588,
    compraId: 141,
    empresaId: 69,
    desconto: true,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_clube_giro",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-clubegiro.mycon.com.br",
    backgroundColor: "#1e5861",
    primaryBackground: "#6dd1a5",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/clube-giro/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Clube%20Giro%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_EASY_CREDITO: {
    botType: BOT_NAME.COLLAB_EASY_CREDITO,
    title: "Mycon e EasyCrédito | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 585,
    compraId: 140,
    empresaId: 68,
    desconto: true,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_easy_credito",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-easycredito.mycon.com.br",
    backgroundColor: "#3369e7",
    primaryBackground: "#1c2b58",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/easy-credito/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=EasyCr%C3%A9dito%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20humanos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_AUTO_MAIS: {
    botType: BOT_NAME.COLLAB_AUTO_MAIS,
    title: "Mycon e Auto+ | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 533,
    compraId: 138,
    empresaId: 65,
    desconto: true,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_auto_mais",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-automais.mycon.com.br",
    backgroundColor: "#231f1f",
    primaryBackground: "#2cb573",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chatbot-cdn.consorciosmycon.com.br/auto-mais/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Auto%2B+%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_AUTO_MAIS_APP: {
    botType: BOT_NAME.COLLAB_AUTO_MAIS_APP,
    title: "Mycon e Auto+ | O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 799,
    compraId: 188,
    empresaId: 108,
    desconto: true,
    navBar: false,
    tipoTabela: "paga_metade",
    brand: "collab_auto_mais",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-automais.mycon.com.br/auto-mais-app",
    backgroundColor: "#231f1f",
    primaryBackground: "#2cb573",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chatbot-cdn.consorciosmycon.com.br/auto-mais/chat-paga-metade.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Auto%2B+%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
  },
  COLLAB_TRUCADAO: {
    botType: BOT_NAME.COLLAB_TRUCADAO,
    title: "Mycon e Trucadão| O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 688,
    compraId: 152,
    empresaId: 80,
    desconto: true,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_trucadao",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-trucadao.mycon.com.br",
    backgroundColor: "#231f1f",
    primaryBackground: "#f6923a",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/trucadao/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=Trucad%C3%A3o%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20especialistas",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  COLLAB_STOPCLUB: {
    botType: BOT_NAME.COLLAB_STOPCLUB,
    title: "Mycon e StopClub| O Consórcio digital com a menor taxa do Brasil.",
    description: "Agora o seu sonho de comprar a sua casa própria vai ficar mais fácil e mais barato.",
    indexar: "noindex,nofollow",
    campanhaId: 542,
    compraId: 136,
    empresaId: 63,
    desconto: true,
    navBar: false,
    tipoTabela: "integral",
    brand: "collab_stopclub",
    gtmId: "GTM-NJWKMFCL",
    siteDomain: "https://simule-stopclub.mycon.com.br",
    backgroundColor: "#163562",
    primaryBackground: "#FF671F",
    primaryForeground: "#ffffff",
    bannerSrc: "https://chabot-cdn.consorciosmycon.com.br/stopclub/chat-padrao.png",
    linkWhatsApp: "https://api.whatsapp.com/send?phone=5511940761248&text=StopClub%20%26%20Mycon.%20%0AOl%C3%A1%2C%20quero%20falar%20com%20o%20time%20de%20humanos",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
};

export default collabs;