import React from "react";
import "./styles.scss";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg"; // Importa o SVG como componente React


import useInitialConfigStore from "../../storeModules/useInitialConfigStore";

const CustomPrevArrow = (props) => {
  const { primaryBackground, primaryForeground } = useInitialConfigStore(
    (state) => state.setThemeColors
  );

  const { className, onClick } = props;

  return (
    <div
      className={`${className} custom-prev-arrow`}
      onClick={onClick}
      style={{
        backgroundColor: primaryBackground,
      }}
    >
      <ArrowIcon
        fill={primaryForeground ? primaryForeground : "#ffffff"}
        style={{
          transform: "rotate(180deg) !important"
        }}
        />
      </div>
  );
};

const CustomNextArrow = (props) => {
  const { primaryBackground, primaryForeground } = useInitialConfigStore(
    (state) => state.setThemeColors
  );

  const { className, onClick } = props;

  return (
    <div
      className={`${className} custom-next-arrow`}
      onClick={onClick}
      style={{
        backgroundColor: primaryBackground,
      }}
    >
      <ArrowIcon
         fill={primaryForeground ? primaryForeground : "#ffffff"}
        style={{
          transform: "rotate(0deg) !important"
        }}
      />
       </div>
  );
};

export { CustomPrevArrow, CustomNextArrow };
