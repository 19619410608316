import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const consorcioInitialState = {
  tipoBem: "",
  tipoPlano: "",
  fetchedPlans: [
    {
      PlanoIndex: 0,
      PlanoID: 0,
      BemID: 0,
      BemNome: "",
      BemCredito: 0,
      BemCreditoLabel: "",
      PlanoMeses: 0,
      PlanoValorParcela: 0,
      PlanoValorParcelaLabel: "",
      PlanoValorPagamento: 0,
      PlanoValorPagamentoLabel: "",
      LabelPlano: "",
      ImagemUrlPlano: "",
    },
  ],
  selectedPlano: {
    PlanoIndex: 0,
    PlanoID: 0,
    BemID: 0,
    BemNome: "",
    BemCredito: 0,
    BemCreditoLabel: "",
    PlanoMeses: 0,
    PlanoValorParcela: 0,
    PlanoValorParcelaLabel: "",
    PlanoValorPagamento: 0,
    PlanoValorPagamentoLabel: "",
    LabelPlano: "",
    ImagemUrlPlano: "",
    exibirTextoResolucao285: false,
    dataAssembleia: "",
  },
  fetchedProposta: {
    PropostaID: 0,
    PropostaToken: "",
    Contrato: "",
    LeadID: 0,
    LeadToken: "",
    formasPagamentoDisponiveis: [],
    Errors: [],
  },
  fetchedPlanDetails: {
    PlanoTaxas: "",
    TabelaDescricao: "",
    Rateio: null,
    SemAdesao: null,
    TaxaAdesao: 0,
    TaxaAdmTotal: 0,
    TaxaFundoReserva: 0,
    TaxaSeguroQuebra: 0,
    Titulo: "",
  },
  fetchedCategorias: [
    {
      categoriaID: "",
      nome: "",
      descricao: "Descrição da categoria",
      imagemUrl: "",
      produtoCategoria: "",
    },
  ],
  categoriaId: 0,
};

export const useConsorcioStore = create()(
  immer(
    devtools(
      (set) => ({
        ...consorcioInitialState,
        setConsorcioStore: (value, type) => {
          set(
            (state) => {
              state[type] = value;
            },
            false,
            `setConsorcioStore - ${type}`
          );
        },
      }),
      { enabled: true, name: "useConsorcioStore" }
    )
  )
);

export default useConsorcioStore;
