import sendTag from "../../../../utils/sendTag";
import useBotControlStore from "../../../../storeModules/useBotControlStore";
import findStep from "../findStep";
import { getPaymentPix } from "../../../../api/requests";
import useConsorcioStore from "../../../../storeModules/useConsorcioStore";
import useInitialConfigStore from "../../../../storeModules/useInitialConfigStore";
import useUserStore from "../../../../storeModules/useUserStore";

const handleGetPaymentPix = () => {
  getPaymentPix();
};

const pagamentoPixSteps = [
  {
    name: "pagamento-pix-registro",
    sender: "bot",
    text: "Certo! Estou <b>registrando</b> o seu <b>Pix</b>",
    action: async () => {
      handleGetPaymentPix();
    },
    component: (
      <img
        className="card-payment"
        src="https://chatbot-cdn.mycon.com.br/consorcio-digital/cards/assets/pix.gif"
        alt="Pix Aprovado"
      />
    ),
  },
  {
    name: "payment-success-conquest",
    sender: "bot",
    isWaitingForFetch: true,
    text: "Prontinho! Parabéns por iniciar a sua conquista!",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-options").index,
      });
    },
  },
  {
    name: "payment-pix-options",
    sender: "bot",
    text: "Como você quer o <b>código pix</b> para pagamento? ",
    options: [
      {
        text: "COPIA E COLA",
        action: async () => {
          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "PIX",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("payment-pix-copy-paste-message").index,
          });
        },
      },
      {
        text: "QRCODE",
        action: async () => {
          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "PIX",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("payment-pix-qrcode-message").index,
          });
        },
      },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-pix-qrcode-message",
    sender: "bot",
    text: "Este é o <b>QRCODE</b> do Pix para o pagamento da sua primeira parcela:",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-qrcode-show").index,
      });
    },
  },
  {
    name: "payment-pix-qrcode-show",
    sender: "bot",
    action: () => {
      const messages = useBotControlStore.getState().messages;
      useBotControlStore.setState({
        messages: [
          ...messages,
          {
            id: "payment-success",
            sender: "bot",
            text: "",
            component: (
              <img
                style={{ width: "200px", height: "200px" }}
                src={useUserStore.getState().payment.pix.url}
                alt="Pix QR code"
              />
            ),
          },
        ],
      });

      useBotControlStore.setState({
        currentStep: findStep("payment-open-bank-app").index,
      });
    },
  },
  {
    name: "payment-pix-qrcode-scan",
    sender: "bot",
    text: "Abra o aplicativo do seu banco e  <b>fotografe o QRCODE</b>",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-qrcode-comeback").index,
      });
    },
  },
  {
    name: "payment-pix-qrcode-comeback",
    sender: "bot",
    text: "<b>Depois que você pagar</b>, volte aqui para continuar:)",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-copy-paste").index,
      });
    },
  },
  {
    name: "payment-pix-copy-paste",
    sender: "bot",
    text: "Escolha uma das opções:",
    options: [
      {
        text: "CONTINUAR",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("payment-pix-copy-paste").index,
          });
        },
      },
      {
        text: "COPIA E COLA",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("payment-pix-copy-paste-message").index,
          });
        },
      },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-pix-payment-confirmation",
    sender: "bot",
    text: "Você já <b>realizou o pagamento</b>?",
    options: [
      {
        text: "SIM",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("payment-pix-payment-confirmation-failed")
              .index,
          });
        },
      },
      { text: "NÃO" },
    ],
  },
  {
    name: "payment-pix-payment-confirmation-failed",
    sender: "bot",
    text: "OPS! Ainda não recebemos a confirmação de que seu PIX foi pago.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-payment-confirmation-bank").index,
      });
    },
  },
  {
    name: "payment-pix-payment-confirmation-bank",
    sender: "bot",
    text: "Se você já efetuou o pagamento, o Banco irá me enviar a confirmação em breve.",
    options: [
      {
        text: "CONTINUAR",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("payment-pix-payment-confirmation-bank-again")
              .index,
          });
        },
      },
    ],
  },
  {
    name: "payment-pix-payment-confirmation-bank-again",
    sender: "bot",
    text: "Deixa eu consultar o seu banco novamente...",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-email-sended").index,
      });
    },
  },
  {
    name: "payment-email-sended",
    sender: "bot",
    text: "Enviei no seu e-mail essa confirmação e as demais orientações.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-important-step-message").index,
      });
    },
  },
  {
    name: "payment-important-step-message",
    sender: "bot",
    text: "Você deu um passo importante para realizar as suas conquistas!",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-for-you-message").index,
      });
    },
  },
  {
    name: "payment-for-you-message",
    sender: "bot",
    text: "Vou <b>torcer muito</b> por você! :)",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("compra-finalizada").index,
      });
    },
  },
  {
    name: "payment-pix-copy-paste-message",
    sender: "bot",
    text: "Este é o código do <b>PIX CÓPIA E COLA:</b>",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-copy-paste-show").index,
      });
    },
  },
  {
    name: "payment-pix-copy-paste-show",
    sender: "bot",
    text: () => {
      return `<p style="line-break: anywhere;">${
        useUserStore.getState().payment.pix.qrCode
      }</p>`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-copy-paste-button").index,
      });
    },
  },
  {
    name: "payment-pix-copy-paste-button",
    sender: "bot",
    text: "<b>Clique</b> no botão <b>para copiar o código</b> o PIX para pagar a sua primeira parcela.",
    options: [
      {
        text: "COPIAR",
        action: () => {
          const { qrCode } = useUserStore.getState().payment.pix;
          navigator.clipboard.writeText(qrCode);

          useBotControlStore.setState({
            currentStep: findStep("payment-pix-copy-paste-confirm").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-pix-copy-paste-confirm",
    sender: "bot",
    text: "Código copiado com sucesso.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-open-bank-app").index,
      });
    },
  },
  {
    name: "payment-pix-copy-paste-bank-message",
    sender: "bot",
    text: "Agora abra o <b>aplicativo do seu banco</b>, escolha a opção:",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-copy-paste-bank-message-select")
          .index,
      });
    },
  },
  {
    name: "payment-pix-copy-paste-bank-message-select",
    sender: "bot",
    text: "Pagar com PIX Cópia e Cola.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-copy-paste-comeback").index,
      });
    },
  },
  {
    name: "payment-pix-copy-paste-comeback",
    sender: "bot",
    text: "<b>Depois que você pagar</b>, volte aqui para continuar:)",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-continue").index,
      });
    },
  },
  {
    name: "pagamento-pix-tentar-novamente",
    sender: "bot",
    text: `<b>OPS! </b>Tivemos um problema para gerar o PIX! O quê você deseja fazer?`,
    options: () => {
      return useConsorcioStore
        .getState()
        .fetchedProposta.formasPagamentoDisponiveis.filter((code) =>
          [5, 0, 1].includes(code)
        )
        .map((code) => {
          const options = {
            5: {
              text: "TENTAR NOVAMENTE",
              action: () => {
                sendTag({ event: "PIX" });

                useBotControlStore.setState({
                  currentStep: findStep("pagamento-pix-registro").index,
                });
              },
            },
            0: {
              text: "PAGAR COM CARTÃO",
              action: () => {
                sendTag({ event: "Cartão de Crédito" });

                useBotControlStore.setState({
                  currentStep: findStep("payment-credit-card-message-secure")
                    .index,
                });
              },
            },
            1: {
              text: "PAGAR COM BOLETO",
              action: () => {
                sendTag({ event: "Boleto Bancário" });

                useBotControlStore.setState({
                  currentStep: findStep("pagamento-boleto-registro").index,
                });
              },
            },
          };
          return options[code];
        })
        .concat([
          {
            text: "FALAR COM NOSSOS ESPECIALISTAS",
            action: () => {
              const linkWhatsApp = useInitialConfigStore.getState().linkWhatsApp;
              window.open(
                linkWhatsApp,
                "_blank"
              );
            },
          },
        ]);
    },
  },
];

export default pagamentoPixSteps;
